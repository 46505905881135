<div class="w-full">
    <app-pagetitle title="{{'CONSIGNMENTS' | translate}}"></app-pagetitle>
    <div class="card">
        <div class="card-body">
            <div>
                <h3><strong>All Order</strong></h3>
                <div class="d-flex mt-3">
                    <!-- <div class="col-md-6">
                        <div class="d-flex">
                            <div class="col-md-4">
                                <div class="dropdown-center">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Centered dropdown
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                      <li><a class="dropdown-item" href="#">Action two</a></li>
                                      <li><a class="dropdown-item" href="#">Action three</a></li>
                                    </ul>
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="dropdown-center">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Centered dropdown
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                      <li><a class="dropdown-item" href="#">Action two</a></li>
                                      <li><a class="dropdown-item" href="#">Action three</a></li>
                                    </ul>
                                  </div>
                            </div>
                            <div class="col-md-4">
                                <div class="dropdown-center">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Centered dropdown
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                      <li><a class="dropdown-item" href="#">Action two</a></li>
                                      <li><a class="dropdown-item" href="#">Action three</a></li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="d-flex">
                            <div class="col-md-4">
                                <div class="dropdown-center">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Centered dropdown
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                      <li><a class="dropdown-item" href="#">Action two</a></li>
                                      <li><a class="dropdown-item" href="#">Action three</a></li>
                                    </ul>
                                  </div>
                            </div>
                            <div class="col-md-8">
                                <div class="dropdown-center">
                                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      Centered dropdown
                                    </button>
                                    <ul class="dropdown-menu">
                                      <li><a class="dropdown-item" href="#">Action</a></li>
                                      <li><a class="dropdown-item" href="#">Action two</a></li>
                                      <li><a class="dropdown-item" href="#">Action three</a></li>
                                    </ul>
                                  </div>
                            </div>
                        </div>
                        
                    </div> -->
                    <!-- <div class="form-floating mr-3">
                        <select class="form-select" id="status1" aria-label="Floating label select example">
                            <option selected>All</option>
                            <option value="1">In Booking Branch</option>
                            <option value="2">Deliveried</option>
                            <option value="3">Delivery Attempt Failed</option>
                            <option value="4">In Transit</option>
                            <option value="5">Ready For Delivery</option>
                            <option value="6">Return</option>
                            <option value="7">Pending</option>
                        </select>
                        <label for="status1">Status</label>
                    </div> -->
                    <div class="form-group mr-3" style="width: 230px; max-width: 230px;">
                        <label for="status1">Status:</label>
                        <select class="form-select" id="status1">
                            <option value="0" selected>All</option>
                            <option value="1">In Booking Branch</option>
                            <option value="2">Deliveried</option>
                            <option value="3">Delivery Attempt Failed</option>
                            <option value="4">In Transit</option>
                            <option value="5">Ready For Delivery</option>
                            <option value="6">Return</option>
                            <option value="7">Pending</option>
                        </select>
                    </div>
                    <div class="form-group mr-3" style="width: 230px; max-width: 230px;">
                        <label for="customerType">Customer Type:</label>
                        <select class="form-select" id="customerType">
                            <option value="0" selected>Sender</option>
                            <option value="1">Receiver</option>
                            
                        </select>
                    </div>
                    <!-- <div class="form-floating mr-3">
                        <select class="form-select" id="customerType" aria-label="Floating label select example">
                            <option selected>Open this select menu</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                        <label for="customerType">Customer Type</label>
                    </div> -->
                    <div class="mr-3">
                        <span><strong>From Date:</strong></span>
                        <div class="input-group clockpicker mt-2">
                            <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                #date="ngbDatepicker">
                            <div class="input-group-append" (click)="date.toggle()">
                                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <div class="mr-3">
                        <span><strong>To Date:</strong></span>
                        <div class="input-group clockpicker mt-2">
                            <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                #date1="ngbDatepicker">
                            <div class="input-group-append" (click)="date1.toggle()">
                                <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="form-group">
                        <label for="selectOption">Select Option</label>
                        <select class="form-select" id="selectOption">
                          <option selected disabled>Select an Option</option>
                          <option value="option1">Option 1</option>
                          <option value="option2">Option 2</option>
                          <option value="option3">Option 3</option>
                        </select>
                      </div> -->
                </div>
                <div class="mt-3">
                    <span><strong>About 542 data found for All status, Customer Type Sender, From Thu Apr 04 2024 To Sat
                            May 04 2024</strong></span>
                </div>
                <div class="d-flex flex-row-reverse mb-3">
                    <button type="button" class="btn btn-outline-primary" (click)="onOpenModalDownload(content)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download mr-1" viewBox="0 0 16 16">
                            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                          </svg>    
                        DOWNLOAD HISTORY
                    </button>
                </div>
                <div class="table-responsive">
                    <table id="basic-datatable" class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                        <thead class="thead-dark">
                            <tr class="text-center align-items-center justify-content-center">
                                <th [width]="150">{{'CN Booking Date' | translate}}</th>
                                <th [width]="100">{{'CN Number' | translate}}</th>
                                <th>{{'Reference Number' | translate}}</th>
                                <th>{{'Booking Branch' | translate}}</th>
                                <th>{{'Customer Name' | translate}}</th>
                                <th>{{'Customer Phone' | translate}}</th>
                                <th>{{'Customer Address' | translate}}</th>
                                <th>{{'Destination Branch' | translate}}</th>
                                <!-- <th>Action</th> -->
                                <!-- <th>{{'TABLE.UpdatedDate' | translate}}</th>
                                <th>{{'TABLE.UpdatedDate' | translate}}</th> -->
                                <!-- <th width="100">{{'TABLE.Actions' | translate}}</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="text-center">
                                <td>04/05/2024</td>
                                <td>
                                    <a href="" style="font-weight: 600 bold; color: darkgreen;">70233000020124</a>
                                </td>
                                <td></td>
                                <td>Baridhara, Dhaka</td>
                                <td>SHAHIN</td>
                                <td>
                                    <a href="">01306308586</a>
                                </td>
                                <td>
                                    EPZ,DHAKA
                                </td>
                                <td>EPZ,Dhaka</td>
                                <!-- <td>{{data.created_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td>
                                <td>{{data.updated_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td> -->

                                <!-- <td class="py-2">
                                    <div class="d-flex" style="gap: 5px">
                                        <a class="font-size-18 text-success" ngbTooltip="{{'Chi tiết' | translate}}">
                                            <i class="mdi mdi-eye"></i>
                            
                                        </a>
                                        <a class="font-size-18" ngbTooltip="{{'Sửa' | translate}}" >
                                            <i class="mdi mdi-pen"></i>
                                            
                                        </a>
                                        <a class="font-size-18 text-danger" ngbTooltip="{{'Xóa' | translate}}">
                                            <i class="mdi mdi-delete"></i>
                                            
                                        </a>
                                        
                                    </div>
        
                                </td> -->
                            </tr>
                            <tr class="text-center">
                                <td>04/05/2024</td>
                                <td>
                                    <a href="" style="font-weight: 600 bold; color: darkgreen;">70233000020124</a>
                                </td>
                                <td></td>
                                <td>Baridhara, Dhaka</td>
                                <td>SHAHIN</td>
                                <td>
                                    <a href="">01306308586</a>
                                </td>
                                <td>
                                    EPZ,DHAKA
                                </td>
                                <td>EPZ,Dhaka</td>
                            </tr>
                            <tr class="text-center">
                                <td>04/05/2024</td>
                                <td>
                                    <a href="" style="font-weight: 600 bold; color: darkgreen;">70233000020124</a>
                                </td>
                                <td></td>
                                <td>Baridhara, Dhaka</td>
                                <td>SHAHIN</td>
                                <td>
                                    <a href="">01306308586</a>
                                </td>
                                <td>
                                    EPZ,DHAKA
                                </td>
                                <td>EPZ,Dhaka</td>
                               
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #content role="document" let-modal="close">
    <div class="modal-header">
        <h3 class="modal-title">{{ "Download Parcel History" | translate}}</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body p-3">
        <div class="text-left">
            <form (ngSubmit)="saveData()" [formGroup]="formData">
                <div class="row">
                    <div class="row col-md-12 ml-1">
                        <div class="form-group w-full">
                            <span><strong>From Date:</strong></span>
                            <div class="input-group clockpicker mt-2">
                                <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                    #date3="ngbDatepicker">
                                <div class="input-group-append" (click)="date3.toggle()">
                                    <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="row col-md-12 ml-1">
                        <div class="form-group w-full">
                            <span><strong>From Date:</strong></span>
                            <div class="input-group clockpicker mt-2">
                                <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                    #date4="ngbDatepicker">
                                <div class="input-group-append" (click)="date4.toggle()">
                                    <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class=" row col-md-12 ml-1">
                        <div class="form-group w-full">
                            <label for="status1">Status:</label>
                            <select class="form-select" id="status1">
                                <option value="0" selected>All</option>
                                <option value="1">In Booking Branch</option>
                                <option value="2">Deliveried</option>
                                <option value="3">Delivery Attempt Failed</option>
                                <option value="4">In Transit</option>
                                <option value="5">Ready For Delivery</option>
                                <option value="6">Return</option>
                                <option value="7">Pending</option>
                            </select>
                        </div>
                        
                    </div>
                    <div class=" row col-md-12 ml-1">
                        <div class="form-group w-full">
                            <label for="customerType">Customer Type:</label>
                            <select class="form-select" id="customerType">
                                <option value="0" selected>Sender</option>
                                <option value="1">Receiver</option>
                                
                            </select>
                        </div>
                        
                    </div>
                    
                    <!-- <div class="form-group row col-md-12">
                        <label class="col-md-2 col-form-label required_label">{{'Mật khẩu' | translate}}</label>
                        <div class="col submitted && form.password.errors}" />
                          
                        </div>
                    </div> -->
                    <div class="row col-md-12 ml-1">
                        <div class="form-group w-full">
                            <button class="btn btn-primary w-full col-md-12">DOWNLOAD</button>
                        </div>
                    </div>
                </div>          

                <!-- <div class="text-right">
                    <button type="button" class="btn btn-secondary btn-sm mr-3"
                        (click)="modal('close click')">{{'Đóng' | translate}}</button>
                    <button tbtnype="submit" class="btn btn-primary btn-sm">{{'Lưu' | translate}}</button>
                </div> -->
            </form>
        </div>
    </div>
</ng-template>