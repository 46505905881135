<div class="w-full">
    <app-pagetitle title="{{'DASHBOARD' | translate}}"></app-pagetitle>
    <div class="card">
        <div class="card-body">
            <div class="d-flex mt-3">
                <div class="col-md-8">
                    <span><strong>Search:</strong></span>
                    <div>
                        <div class="">
                            <label class="mr-3">
                                <input [value]="0" [(ngModel)]="showInput" name="showInput" type="radio"
                                    [checked]="isChecked" /> CN Number
                            </label>
                            <label class="mr-3">
                                <input [value]="1" [(ngModel)]="showInput" name="showInput" type="radio"
                                    [checked]="!isChecked" /> Receiver Contact
                            </label>
                            <label class="mr-3">
                                <input [value]="2" [(ngModel)]="showInput" name="showInput" type="radio"
                                    [checked]="!isChecked" /> Sender Contact
                            </label>
                        </div>
                    </div>
                    <div class="" *ngIf="showInput == 0">

                        <div class="input-group mb-3" style="max-width: 350px;">
                            <input type="text" class="form-control" placeholder="CN Number">
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" type="button">search</button>
                            </div>
                        </div>
                        <div class="">

                        </div>
                    </div>
                    <div class="d-flex" *ngIf="showInput == 1">

                        <div class="input-group mb-3" style="max-width: 350px;">
                            <input type="text" class="form-control" placeholder="Receiver Contact">
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" type="button">search</button>
                            </div>
                        </div>
                        <div class="">

                        </div>
                    </div>
                    <div class="d-flex" *ngIf="showInput == 2">

                        <div class="input-group mb-3" style="max-width: 350px;">
                            <input type="text" class="form-control" placeholder="Sender Contact">
                            <div class="input-group-append">
                                <button class="btn btn-outline-success" type="button">search</button>
                            </div>
                        </div>
                        <div class="">

                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="d-flex">
                        <div class="col-md-6">
                            <span><strong>From Date:</strong></span>
                            <div class="input-group clockpicker ">
                                <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                    #date="ngbDatepicker">
                                <div class="input-group-append" (click)="date.toggle()">
                                    <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <span><strong>To Date:</strong></span>
                            <div class="input-group clockpicker ">
                                <input ngbDatepicker class="form-control" autoClose="false" placeholder="yyyy-mm-dd"
                                    #date1="ngbDatepicker">
                                <div class="input-group-append" (click)="date1.toggle()">
                                    <span class="input-group-text"><i class="mdi mdi-calendar"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tabset">
                <!-- Tab 1 -->
                <input type="radio" name="tabset" id="tab1" aria-controls="senderallorder" checked>
                <label for="tab1">Sender all order</label>
                <!-- Tab 2 -->
                <input type="radio" name="tabset" id="tab2" aria-controls="senderordersinprogress">
                <label for="tab2">Sender Orders in progress</label>
                <!-- Tab 3 -->
                <input type="radio" name="tabset" id="tab3" aria-controls="senderpaymentsummary">
                <label for="tab3">Sender Payment Summary</label>

                <input type="radio" name="tabset" id="tab4" aria-controls="receiverallorders">
                <label for="tab4">Receiver All orders</label>
                <input type="radio" name="tabset" id="tab5" aria-controls="receiverordersinprogress">
                <label for="tab5">Receiver Orders in progress</label>

                <div class="tab-panels">
                    <section id="senderallorder" class="tab-panel">
                        <div _ngcontent-ytr-c4="" class="row">
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        >
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fdelivered2.png&w=96&q=75"
                                            alt="">
                                        <div><span style="color: rgb(32, 134, 122);">5</span> <br>
                                            <span style="color: rgb(2, 149, 34);">Order Created</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fshipped3.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Product Delivered</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fparcel2.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Product Returned</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fbox2.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Delivery Pending</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                    <section id="senderordersinprogress" class="tab-panel">
                        <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                            <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                <div _ngcontent-ytr-c4=""
                                    class="card-body d-flex align-items-center justify-content-between card_bg1"
                                    style="color: rgb(2, 149, 34);">
                                    <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fdelivery-man2.png&w=96&q=75"
                                        alt="">
                                    <div><span>9</span> <br>
                                        <span>Ready For Delivery</span>
                                    </div>
                                    <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" fill="currentColor"
                                            class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                            <path
                                                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                            <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                <div _ngcontent-ytr-c4=""
                                    class="card-body d-flex align-items-center justify-content-between card_bg1"
                                    style="color: rgb(2, 149, 34);">
                                    <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fsupermarket2.png&w=96&q=75"
                                        alt="">
                                    <div><span>6</span> <br>
                                        <span>In Transit</span>
                                    </div>
                                    <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" fill="currentColor"
                                            class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                            <path
                                                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                            <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                <div _ngcontent-ytr-c4=""
                                    class="card-body d-flex align-items-center justify-content-between card_bg1"
                                    style="color: rgb(2, 149, 34);">
                                    <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FdeliveryAttemptFailed.png&w=96&q=75"
                                        alt="">
                                    <div><span>53</span> <br>
                                        <span>Delivery Attempt Failed</span>
                                    </div>
                                    <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="24" height="24" fill="currentColor"
                                            class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                            <path
                                                d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                    <section id="senderpaymentsummary" class="tab-panel">
                        <div _ngcontent-ytr-c4="" class="row">
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FtotalAmountBooked.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Order Created</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FTOTALCONDITIONDELIVERED.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Product Delivered</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FTOTALCONDITIONPENDING.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Product Returned</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FTOTALCONDITIONRECEIVED.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Delivery Pending</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                    <section id="receiverallorders" class="tab-panel">
                        <div _ngcontent-ytr-c4="" class="row">
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fdelivered2.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Total Amount Booked</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fshipped3.png&w=96&q=75"
                                            alt="">
                                        <div><span>7</span> <br>
                                            <span>Total Amount Paid</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fparcel2.png&w=96&q=75"
                                            alt="">
                                        <div><span>5</span> <br>
                                            <span>Total Amount Pending</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fbox2.png&w=96&q=75"
                                            alt="">
                                        <div><span>95</span> <br>
                                            <span>Total Amount Available</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                    <section id="receiverordersinprogress" class="tab-panel">
                        <div _ngcontent-ytr-c4="" class="row">
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fdelivery-man2.png&w=96&q=75"
                                            alt="">
                                        <div><span>9</span> <br>
                                            <span>Ready For Delivery</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2Fsupermarket2.png&w=96&q=75"
                                            alt="">
                                        <div><span>6</span> <br>
                                            <span>In Transit</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div _ngcontent-ytr-c4="" class="col-xl-3 col-md-6 btn">
                                <div _ngcontent-ytr-c11="" class="card text-white mb-4 card_bg1">
                                    <div _ngcontent-ytr-c4=""
                                        class="card-body d-flex align-items-center justify-content-between card_bg1"
                                        style="color: rgb(2, 149, 34);">
                                        <img width="80" height="80" src="https://customer.sundarbancourierltd.com/_next/image?url=%2Fimage%2FdeliveryStatus%2FdeliveryAttemptFailed.png&w=96&q=75"
                                            alt="">
                                        <div><span>53</span> <br>
                                            <span>Delivery Attempt Failed</span>
                                        </div>
                                        <div _ngcontent-ytr-c11="" class="mt-3"><svg xmlns="http://www.w3.org/2000/svg"
                                                width="24" height="24" fill="currentColor"
                                                class="bi bi-arrow-right-circle-fill icon-link" viewBox="0 0 16 16">
                                                <path
                                                    d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
                                            </svg>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </section>
                    <div class="d-flex justify-content-center align-items-center m-auto" style="gap: 10px; font-size: 17px;">
                        <p>
                            <strong>For any support: Phone: <a href="" class="text-info">0868866967</a></strong>
                        </p>
                        <p>
                            <strong>Email: <a href="" class="text-info">mail@onelink.com</a></strong>
                        </p>
                    </div>  
                </div>

            </div>

        </div>
    </div>
</div>



<!-- 
  
  Radio version of tabs.

  Requirements:
  - not rely on specific IDs for CSS (the CSS shouldn't need to know specific IDs)
  - flexible for any number of unkown tabs [2-6]
  - accessible

  Caveats:
  - since these are checkboxes the tabs not tab-able, need to use arrow keys

  Also worth reading:
  http://simplyaccessible.com/article/danger-aria-tabs/
-->