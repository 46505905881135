<div class="w-full">
    <!-- <app-pagetitle title="{{'PAGE.REPORT.IncidentType' | translate}}" [breadcrumbItems]="breadCrumbItems"></app-pagetitle> -->
    <app-pagetitle title="{{'TRACKING' | translate}}"></app-pagetitle>
    <div class="card">
        <div class="card-body">
            <div class="d-flex justify-content-center">
                <div class="">
                    <h2 class="d-flex justify-content-center">{{'Track Your Parcel' | translate}}</h2><br>
                    <span>Need the status of your shipment or proof of delivery? Enter your tracking number or reference
                        number below.</span>
                    <div class="input-group mb-3 mt-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text">Consignment Number (CN No.)</span>
                        </div>
                        <input type="text" class="form-control" aria-label="Amount (to the nearest dollar)" [(ngModel)]="searchItem">
                        <div class="input-group-append">
                            <button class="btn btn-primary" (click)="filter()">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-search" viewBox="0 0 16 16">
                                    <path
                                        d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-md-2" *ngIf="filteredItems && countProductFilter == 0">
                <div class="row">
                    <div class="col-sm-8 ">
                        <div class="table-responsive">
                            <table id="basic-datatable"
                                class="table table-bordered dt-responsive nowrap  no-footer dtr-inline">
                                <thead class="table-success">
                                    <tr class="bg-primary">

                                        <th [width]="350">70233000020487  09/05/2024 08:02</th>
                                        <th>{{'
                                            The parcel was sent to Elephant Road,Dhaka from Dhaka (Head Office).' | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody  *ngFor="let data of listData;let i=index">
                                    <tr class="table-light">
                                        <!-- <td>{{pageSize*(pageIndex-1) +i+1}}</td> -->
                                        <td>{{data.created_at | date:'dd/MM/yyyy HH:mm':'GMT'}}</td>
                                        <td> The parcel was sent to Elephant Road,Dhaka from Dhaka (Head Office).</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <button class="btn btn-primary mb-2" (click)="onOpenModalSubmitAComplain(content)">Submit a complain</button><br>
                        <span>Helpline</span><br>
                        <div class="d-flex" style="gap: 10px;">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="fill: red"
                                fill="currentColor" class="bi bi-telephone" viewBox="0 0 16 16">
                                <path
                                    d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg>
                            <span style="color: red;">0868866967</span>
                        </div>
                        <h3 class="mb-3">Consignment Info</h3><br>
                        <span>Consignment ID</span><br>
                        <span><strong>70233000020487</strong></span><br>
                        <span>Shareable Short Tracking Link</span><br>
                        <span><strong>http://t.scsapps.xyz?c=70233000020487</strong></span><br>
                        <span>Customer Name</span><br>
                        <span><strong>ZAHID</strong></span><br>
                        <span>Customer Phone</span><br>
                        <span><strong>01911405364</strong></span><br>
                        <span>Customer Address</span><br>
                        <span><strong>SHIMANTO SQUARE MARKET LEVEL 5 ROAD-2 DHANMONDI DHK</strong></span><br>
                        <span>Sender Name</span><br>
                        <span><strong>NOBA GLOBAL</strong></span><br>
                        <span>Sender Phone</span><br>
                        <span><strong>01576990720</strong></span><br>
                        <span>Sender Address</span><br>
                        <div class="mb-3">
                            <span><strong>BARIDHARA, DHAKA</strong></span><br>
                        </div>
                        <span>Booking Date</span><br>
                        <span><strong>2024-05-08</strong></span><br>
                        <span>Delivery Type</span><br>
                        <span><strong>Home Delivery</strong></span><br>
                        <span>Destination Branch</span><br>
                        <span><strong>Dilkusha</strong></span><br>
                        <span>Lot Quantity</span><br>
                        <span><strong>1</strong></span><br>
                        <div class="mt-3">
                            <span>Delivery Charge <strong> 70</strong></span><br>
                        </div>
                        <span>Total COD Receivable <strong>1990</strong></span><br>
                        <span>Receiver Payable <strong>1900</strong></span><br>
                        <span>VD Charge <strong>0</strong></span><br>
                        <span>Delivery Contact Name</span><br>
                        <span>Delivery Contact Phone Number</span><br>
                    </div>
                </div>
            </div>


        </div>
    </div>

    <ng-template #content role="document" let-modal="close">
        <div class="modal-header">
            <h3 class="modal-title">{{ "Submit Complain" | translate}}</h3>
            <span></span>
            <button type="button" class="close" aria-label="Close" (click)="modal('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <span class="ml-3 mt-1">To submit a complain, please enter your email address and phone number here. We will send updates as soon as possible.</span>
        <div class="modal-body p-3">
            <div class="text-left">
                <form (ngSubmit)="saveData()" [formGroup]="formData">
                    <div class="row">
                        
                        <div class=" row col-md-12 ml-1">
                            <div class="form-group w-full">
                                <label for="address1">Status:</label>
                                <!-- <select class="form-select" id="status1">
                                    <option value="0" selected>All</option>
                                    <option value="1">In Booking Branch</option>
                                    <option value="2">Deliveried</option>
                                    <option value="3">Delivery Attempt Failed</option>
                                    <option value="4">In Transit</option>
                                    <option value="5">Ready For Delivery</option>
                                    <option value="6">Return</option>
                                    <option value="7">Pending</option>
                                </select> -->
                                <input type="text" class="form-control" name="address1" id="address1">
                            </div>
                            
                        </div>
                        <div class=" row col-md-12 ml-1">
                            <div class="form-group w-full">
                                <label for="phoneNumber">Phone Number:</label>
                                <input type="text" class="form-control" name="phoneNumber" id="phoneNumber">
                            </div>
                            
                        </div>

                          <div class=" row col-md-12 ml-1">
                            <div class="form-group w-full">
                                <label for="yourPhone" class="form-label">Your Complain:</label>
                            <textarea class="form-control" id="yourPhone" placeholder="Write your complain here" rows="3"></textarea>
                          </div>
                            
                        </div>
                        <!-- <div class="form-group row col-md-12">
                            <label class="col-md-2 col-form-label required_label">{{'Mật khẩu' | translate}}</label>
                            <div class="col submitted && form.password.errors}" />
                              
                            </div>
                        </div> -->
                        <!-- <div class="row col-md-12 ml-1">
                            <div class="form-group w-full">
                                <button class="btn btn-primary w-full col-md-12">DOWNLOAD</button>
                            </div>
                        </div> -->
                    </div>          
    
                    <div class="text-right">
                        <button type="button" class="btn btn-secondary btn-sm mr-3"
                            (click)="modal('close click')">{{'Cancel' | translate}}</button>
                        <button tbtnype="submit" class="btn btn-primary btn-sm">{{'Submit' | translate}}</button>
                    </div>
                </form>
            </div>
        </div>
    </ng-template>